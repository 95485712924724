import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContentContainer = styled.div`
  .coupon {
    width: 450px;
    margin: 0 auto;
  }
`

export default ({ data }) => {
  const couponImage = data.couponImage

  return (
    <Layout>
      <SEO title="Coupons" />
      <ContentContainer>
        <p>
          What’s better than enjoying an oven-fresh Mama’s Pizza? Getting a
          great deal on it with your shiny new VIP Discount! Show this little
          gem on your mobile device to the cashier at time of purchase.
        </p>
        <p>Mama’s VIP Discount (Show on your phone or print this page)</p>
        <Img className="coupon" fluid={couponImage.childImageSharp.fluid} />
      </ContentContainer>
    </Layout>
  )
}

export const query = graphql`
  query CouponsQuery {
    couponImage: file(relativePath: { eq: "mamascouponcomp.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
